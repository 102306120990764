<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../../app.config'
import Upload from './components/upload-component'

import { jobService } from '../../../../helpers/jobs/jobs.service'

/**
 * Order-detail component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Upload,
  },
  page: {
    title: 'Order-detail',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      environment: '',
      job: {
        jobNumber: '',
        jobType: '',
        company: '',
        contact: '',
        jobDate: '',
        status: '',
      },
      title: 'Order Detail',
      items: [
        {
          text: 'MultiPress',
        },
        {
          text: 'Orders',
          to: '/multipress/orders',
        },
        {
          text: 'Order Detail',
          active: true,
        },
      ],
      filelist: [],
    }
  },
  methods: {
    async getJob() {
      const order = await jobService.getJob(this.$route.params.id)
      if (order.status === 200) {
        this.job = order.data.data
      }
    },
  },
  middleware: 'router-auth',
  async mounted() {
    this.getJob()
    this.environment = process.env.NODE_ENV
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header border-bottom bg-transparent">
            <h5 class="header-title mb-0">Job #{{ job.jobNumber }}</h5>
          </div>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <div class="media mb-2">
                    <div class="mr-2 align-self-center">
                      <i class="ri-hashtag h2 m-0 text-muted"></i>
                    </div>
                    <div class="media-body">
                      <p class="mb-1">Job No.</p>
                      <h5 class="mt-0">#{{ job.jobNumber }}</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media mb-2">
                    <div class="mr-2 align-self-center">
                      <i class="ri-user-2-line h2 m-0 text-muted"></i>
                    </div>
                    <div class="media-body">
                      <p class="mb-1">Company</p>
                      <h5 class="mt-0">{{ job.company }}</h5>
                      <p>{{ job.contact }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media mb-2">
                    <div class="mr-2 align-self-center">
                      <i class="ri-calendar-event-line h2 m-0 text-muted"></i>
                    </div>
                    <div class="media-body">
                      <p class="mb-1">Date</p>
                      <h5 class="mt-0">
                        {{ new Date(job.jobDate).toLocaleDateString('nl-NL') }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media mb-2">
                    <div class="mr-2 align-self-center">
                      <i class="ri-map-pin-time-line h2 m-0 text-muted"></i>
                    </div>
                    <div class="media-body">
                      <p class="mb-1">Status</p>
                      <h5 class="mt-0">{{ job.status }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <div class="table-responsive">
                      <table class="table table-centered table-sm mb-lg-0">
                        <thead class="bg-light">
                          <tr>
                            <th colspan="4">Overzicht</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="subjob in job.subJob">
                            <tr :key="subjob.id + Math.random()">
                              <td colspan="4">
                                <div class="media align-items-center">
                                  <div class="mr-3">
                                    <!-- <img src="@/assets/images/products/poster.png" alt="subjob.description" height="160" /> -->
                                  </div>
                                  <div class="media-body">
                                    <h5>{{ subjob.name }}</h5>
                                    <p class="mb-1">Producttype: {{ subjob.productType }}</p>
                                    <table class="table table-centered table-sm mg-lg-0">
                                      <thead>
                                        <tr>
                                          <th width="70%">Details</th>
                                          <th>Afmeting</th>
                                          <th>Pagina's</th>
                                          <th>Kleur</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody class="table table-striped table-sm">
                                        <template v-for="attribute in subjob.attributes">
                                          <tr :key="attribute.id + Math.random()">
                                            <td>
                                              <div class="row">
                                                <span class="col-lg-2"><strong>Omschrijving</strong></span>
                                                {{ attribute.description }}
                                              </div>
                                              <div class="row">
                                                <span class="col-lg-2"><strong>Materiaal</strong></span>
                                                <span>{{ subjob.paper }}</span>
                                              </div>
                                              <div class="row">
                                                <span class="col-lg-2"><strong>Bedrukking</strong></span>
                                                <span>{{ subjob.colors }}</span>
                                              </div>
                                              <div class="row">
                                                <span class="col-lg-2"><strong>Aantal</strong></span>
                                                <span>{{ subjob.copies }}</span>
                                              </div>
                                            </td>
                                            <td>
                                              <strong>{{ attribute.width_net }} x {{ attribute.height_net }} mm</strong>
                                            </td>
                                            <td>
                                              <strong>{{ attribute.pages }}</strong>
                                            </td>
                                            <td>
                                              <strong>{{ attribute.colorsFront }} / {{ attribute.colorsBack }}</strong>
                                            </td>
                                            <!-- <td><Upload :attribute="attribute" @changed="getJob" /></td> -->
                                            <!-- <td><Upload :attribute="attribute" :jobId="job.id" :jobNumber="job.jobNumber" /></td> -->
                                          </tr>
                                          <tr v-if="attribute.upload.files.length" :key="attribute.upload.id">
                                            <td colspan="5">
                                              <div class="row" v-for="file in attribute.upload.files" :key="file.id">
                                                <div class="col-md-4" v-for="page in file.pages" :key="page.id">
                                                  <b-card text-variant="secondary">
                                                    <b-card-title>
                                                      <h5 class="card-title">Page: {{ page.pageNumber }}</h5>
                                                    </b-card-title>
                                                    <blockquote>
                                                      <b-card-text>
                                                        <div>
                                                          Size: {{ page.trimBoxWidth }} x {{ page.trimBoxHeight }} mm<br />
                                                          Bleed: <span v-if="page.bleed === 0" class="badge badge-outline-warning">{{ page.bleed }}</span
                                                          ><span v-else class="badge badge-outline-success">{{ page.bleed }}</span> mm<br />
                                                        </div>
                                                        <h5>Fonts</h5>
                                                        <p v-for="font in page.fonts" :key="font.id" class="mt-1">
                                                          Font: {{ font.name }}<br />
                                                          Embedded: <span v-if="font.embedded" class="badge badge-outline-success">Yes</span
                                                          ><span v-else badge badge-outline-error>No</span><br />
                                                        </p>
                                                      </b-card-text>
                                                    </blockquote>
                                                  </b-card>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr v-if="attribute.upload.checkResult.length" :key="attribute.upload.id">
                                            <td colspan="5">
                                              <div class="row">
                                                <div class="col-lg-2 m-3">
                                                  <span v-if="attribute.upload.isChecked" class="badge badge-success font-14">Gecontroleerd</span>
                                                  <span v-if="!attribute.upload.isChecked" class="badge badge-danger font-14">Nog niet gecontroleerd</span>
                                                </div>
                                                <div class="col-lg-2 m-3">
                                                  <span v-if="attribute.upload.hasCheckError" class="badge badge-danger font-14">Fouten gevonden</span>
                                                  <span v-if="!attribute.upload.hasCheckError" class="badge badge-success font-14">Geen fouten gevonden</span>
                                                </div>
                                                <div class="col-lg-2 m-3">
                                                  <span v-if="job.isAgreed" class="badge badge-success font-14">Akkoord klant</span>
                                                  <span v-if="!job.isAgreed" class="badge badge-danger font-14">Klant nog niet akkoord</span>
                                                </div>
                                              </div>
                                              <span v-for="result in attribute.upload.checkResult" :key="result.id" style="display: inline-block; float: left">
                                                <img
                                                  :src="`https://api.jouwpdf.com:4000/${attribute.upload.id}/${result.filename}`"
                                                  alt=""
                                                  v-if="result.resultType === 'preview' && environment === 'production'"
                                                  style="max-height: 150px"
                                                  class="mr-1 mb-1"
                                                />
                                                <img
                                                  :src="`https://localhost:4000/${attribute.upload.id}/${result.filename}`"
                                                  alt=""
                                                  v-if="result.resultType === 'preview' && environment === 'development'"
                                                  style="max-height: 150px"
                                                  class="mr-1 mb-1 border"
                                                />
                                              </span>
                                            </td>
                                          </tr>
                                        </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr :key="subjob.id">
                              <td v-if="subjob.deliveryAddress.length" colspan="5">
                                <h4>Afleveradressen</h4>
                                <span
                                  text-variant="secondary"
                                  v-for="address in subjob.deliveryAddress"
                                  :key="address.id"
                                  style="display: inline-block"
                                  class="col-2"
                                >
                                  <h4>{{ address.company }}</h4>

                                  {{ address.contact }}<br />
                                  {{ address.address }}<br />
                                  {{ address.zipcode }}<br />
                                  {{ address.city }}<br />
                                  {{ address.country }}<br />
                                  {{ address.companyCode }}<br />
                                  <strong>Aantal: {{ address.quantity }}</strong>
                                </span>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
        <div class="row mb-3">
          <div class="col-lg-4" v-for="address in job.delivery_address" :key="address.id">
            <div>
              <div class="card p-2 mb-lg-0">
                <table class="table table-borderless table-sm mb-0">
                  <thead class="bg-light">
                    <tr>
                      <th colspan="4">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Company:</th>
                      <td>
                        <span class="font-17">{{ address.details.company }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Address:</th>
                      <td>{{ address.details.address }} {{ address.details.address_number }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Zipcode:</th>
                      <td>{{ address.details.zipcode }}</td>
                    </tr>
                    <tr>
                      <th scope="row">City:</th>
                      <td>{{ address.details.city }} {{ address.details.country_code }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
