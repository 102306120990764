import api from '../axios'

/**
 *
 * @param {*} startdate
 * @param {*} stopdate
 * @param {*} type
 * :relation/:type/:startdate/:enddate
 */
const getJobs = async (perPage, page) => {
  return await api().get(`jobs?page=${page}&limit=${perPage}`)
}

const getJob = async (id) => {
  return await api().get(`jobs/${id}`)
}

const syncJobs = async () => {
  return await api().put('jobs/syncjobs')
}

const emailJob = async (id, withButton = true) => {
  return await api().post(`jobs/${id}/email`, { withButton })
}

const createUpload = async (jobId, subJobid) => {}

const findJob = async (searchString) => {
  return await api().post(`jobs/find`, { searchString })
}

export const jobService = {
  getJobs,
  getJob,
  emailJob,
  syncJobs,
  createUpload,
  findJob,
}
