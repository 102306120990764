<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="float-right border mb-2">
              <ul class="list-unstyled topnav-menu float-right mb-0">
                <li class="dropdown d-none d-lg-inline-block">
                  <b-nav-item-dropdown variant="white" class="d-none d-lg-inline-block" right toggle-class="header-item">
                    <template v-slot:button-content>
                      <img class :src="flag" alt="Header Language" height="16" />
                      {{ text }}
                    </template>
                    <b-dropdown-item
                      class="notify-item"
                      v-for="(entry, i) in languages"
                      :key="`Lang${i}`"
                      :value="entry"
                      @click="setLanguage(entry.language, entry.title, entry.flag)"
                      :link-class="{ active: entry.language === current_language }"
                    >
                      <img :src="`${entry.flag}`" alt="user-image" class="mr-1" height="12" />
                      <span class="align-middle">{{ entry.title }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </li>
              </ul>
            </div>
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/jouw-pdf.png" alt height="auto" />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/jouw-pdf.png" alt width="250" />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                {{ $t('pages.account.register.createaccount') }}
              </p>
            </div>

            <form action="#" @submit.prevent="tryToRegisterIn">
              <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{ $t('pages.account.register.success') }}.</b-alert>

              <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible :show="notificationAutoCloseDuration">{{ regError }}</b-alert>

              <b-alert :variant="notification.type" class="mt-3" v-if="notification.message" :show="notificationAutoCloseDuration" dismissible>{{
                notification.message
              }}</b-alert>

              <div class="form-group">
                <label for="fullname">{{ $t('pages.account.register.username') }}</label>
                <input
                  class="form-control"
                  v-model="user.username"
                  type="text"
                  id="fullname"
                  placeholder="Enter your name"
                  :class="{
                    'is-invalid': submitted && $v.user.username.$error,
                  }"
                />
                <div v-if="submitted && !$v.user.username.required" class="invalid-feedback">Username is required.</div>
              </div>
              <div class="form-group">
                <label for="emailaddress">{{ $t('pages.account.register.email') }}</label>
                <input
                  class="form-control"
                  v-model="user.email"
                  type="email"
                  id="emailaddress"
                  :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                  placeholder="Enter your email"
                />
                <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                  <span v-if="!$v.user.email.required">Email is required.</span>
                  <span v-if="!$v.user.email.email">Please enter valid email.</span>
                </div>
              </div>
              <div class="form-group">
                <label for="password">{{ $t('pages.account.register.password') }}</label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    v-model="user.password"
                    id="password"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.user.password.$error,
                    }"
                    placeholder="Enter your password"
                  />
                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text">
                      <span class="password-eye"></span>
                    </div>
                  </div>
                  <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">{{ $t('pages.account.register.passwordrequired') }}.</div>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="checkbox-signup" />
                  <label class="custom-control-label" for="checkbox-signup">
                    {{ $t('pages.account.register.accept') }}
                    <a href="javascript: void(0);" class="text-dark">{{ $t('pages.account.register.terms') }}</a>
                  </label>
                </div>
              </div>
              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  {{ $t('pages.account.register.signup') }}
                </button>
              </div>
            </form>

            <div class="text-center">
              <h5 class="mt-3 text-muted">{{ $t('pages.account.register.signupwith') }}</h5>
              <ul class="social-list list-inline mt-3 mb-0">
                <li class="list-inline-item">
                  <a href="javascript: void(0);" class="social-list-item border-purple text-purple">
                    <i class="mdi mdi-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript: void(0);" class="social-list-item border-danger text-danger">
                    <i class="mdi mdi-google"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript: void(0);" class="social-list-item border-info text-info">
                    <i class="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript: void(0);" class="social-list-item border-secondary text-secondary">
                    <i class="mdi mdi-github"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              {{ $t('pages.account.register.already') }}?
              <router-link to="/login" class="text-primary font-weight-medium ml-1">{{ $t('pages.account.register.signin') }}</router-link>
            </p>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<script>
import Auth from '../../layouts/auth'
import appConfig from '../../../../app.config'

import { required, email } from 'vuelidate/lib/validators'

/**
 * Register component
 */
export default {
  page: {
    title: 'Register',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  name: 'account-register',
  data() {
    return {
      user: {
        username: '',
        email: '',
        password: '',
      },
      submitted: false,
      languages: [
        {
          flag: require('@/assets/images/flags/dutch.jpg'),
          language: 'nl',
          title: 'Nederlands',
        },
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English',
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    }
  },
  components: {
    Auth,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0
    },
  },
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  created() {},
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
  },
  methods: {
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          this.tryingToRegister = true
          // Reset the regError if it existed.
          this.regError = null
          return (
            this.$store
              .dispatch('auth/register', {
                email: this.user.email,
                password: this.user.password,
              })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToRegister = false
                this.isRegisterError = false
                this.registerSuccess = true
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || {
                      path: '/',
                    },
                  )
                }
              })
              .catch((error) => {
                this.tryingToRegister = false
                this.regError = error ? error : ''
                this.isRegisterError = true
              })
          )
        } else if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
          const { email, username, password } = this.user
          if (email && username && password) {
            this.$store.dispatch('authfack/registeruser', this.user)
            this.$store.dispatch('notification/clear')
          }
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale
      this.current_language = locale
      this.text = country
      this.flag = flag
    },
  },
}
</script>
