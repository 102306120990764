import { userService } from '../../helpers/authservice/user.service'
import router from '../../router/index'

export const state = {
  user: {
    firstname: null,
    lastname: null,
  },
}

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  async login({ dispatch, commit }, { email, password }) {
    commit('loginRequest', { email })

    await userService.login(email, password).then(
      (user) => {
        commit('loginSuccess', user)
        router.push('/multipress/orders')
      },
      (error) => {
        commit('loginFailure', error)
        dispatch('notification/error', error, { root: true })
      },
    )
  },
  // Logout the user
  logout({ commit }) {
    commit('logout')
    localStorage.removeItem('user')
    localStorage.removeItem('pressferToken')
  },
  // register the user
  registeruser({ dispatch, commit }, user) {
    commit('registerRequest', user)
    userService.register(user).then(
      (user) => {
        commit('registerSuccess', user)
        dispatch('notification/success', 'Registration successful', { root: true })

        router.push('/login')
      },
      (error) => {
        commit('registerFailure', error)
        dispatch('notification/error', error, { root: true })
      },
    )
  },

  async validateUser({ dispatch, commit }) {
    const user = JSON.parse(localStorage.getItem('user'))

    // Validate token
    const token = localStorage.getItem('pressferToken')
    if (!token || !user) {
      commit('logout')
      // router.push('/login')
      return
    }

    // If both user and token are present, validate!
    const response = await userService.validateToken(token)

    if (response.status !== 200) {
      console.log('error niet 200')
      commit('logout')
      router.push('/login')
      return
    }

    commit('validateSuccess', user)
  },
}

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true }
    state.user = user
    state.user.firstname = user.firstname
    state.user.lastname = user.lastname
  },
  validateSuccess(state, user) {
    state.status = { loggeduser: true }
    state.user = user
    state.user.firstname = user.firstname
    state.user.lastname = user.lastname
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.user = null
  },
  registerRequest(state) {
    state.status = { registering: true }
  },
  registerSuccess(state) {
    state.status = {}
  },
  registerFailure(state) {
    state.status = {}
  },
}

export const getters = {
  getFullName(state) {
    return `${state.user.firstname} ${state.user.lastname}`
  },
}
