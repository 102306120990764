<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../app.config'

import Swal from 'sweetalert2'
import Switches from 'vue-switches'

import { required, ipAddress, numeric } from 'vuelidate/lib/validators'
import { serverService } from '../../../helpers/servers/servers.service'

/**
 * Companies component
 */
export default {
  page: {
    title: 'Pressfer Servers',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Switches,
  },
  data() {
    return {
      servers: [],
      title: 'Servers',
      items: [
        {
          text: 'Pressfer',
          href: '/',
        },
        {
          text: 'Apps',
          href: '/',
        },
        {
          text: 'Servers',
          active: true,
        },
      ],
      showmodal: false,
      submitted: false,
      loading: false,
      server: {
        tag: '',
        ipaddress: '',
        port: '',
        username: '',
        password: '',
        serverType: '',
        isActive: false,
      },
    }
  },
  validations: {
    server: {
      tag: {
        required,
      },
      ipaddress: {
        required,
        ipAddress,
      },
      port: {
        required,
        numeric,
      },
      username: {
        required,
      },
      password: {
        required,
      },
      serverType: {
        required,
      },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    async handleSubmit(e) {
      try {
        this.loading = true
        this.submitted = true

        // stop here if form is invalid
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.loading = false
          return
        } else {
          let server

          if (!this.server.id) {
            server = await serverService.createServer(this.server)
          } else {
            server = await serverService.updateServer(this.server)
          }
          switch (server.status) {
            case 201:
              this.$toast.success('Server toegevoegd', {
                position: 'top-right',
                duration: 3000,
              })
              break
            case 200:
              this.$toast.success('Server gewijzigd', {
                position: 'top-right',
                duration: 3000,
              })
              break
            case 204:
              this.$toast.success('Server verwijderd', {
                position: 'top-right',
                duration: 3000,
              })
              break
          }
          this.getServers()
          this.showmodal = false
        }
        this.submitted = false
        this.server = {}
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    /**
     * Modal hide on close
     */
    hideModal(e) {
      this.submitted = false
      this.showmodal = false
      this.server = {}
    },
    setPort() {
      if (this.server.serverType === 'multipress') this.server.port = 8081
      if (this.server.serverType === 'switch') this.server.port = 51088
    },
    async getServers() {
      const servers = await serverService.getServers()
      this.servers = servers.data
    },
    async editServer(id) {
      const server = await serverService.getServer(id)
      this.server = server.data.data

      this.showmodal = true
    },
    async activateServer(id, isActive) {
      this.server.id = id
      this.server.isActive = isActive
      await serverService.updateServer(this.server)
      this.getServers()
    },
    async deleteServer(id) {
      const server = await serverService.deleteServer(id)
      if (server.status === 204) {
        return true
      }
      return false
    },
    async confirmDelete(id) {
      Swal.fire({
        title: `Server verwijderen?`,
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00c98e',
        cancelButtonColor: '#d33',
        background: '#2b2f3a',
        confirmButtonText: 'Ja, verwijderen!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await this.deleteServer(id)
          if (!deleted) {
            Swal.fire('Oeps!', 'Dat gaat niet goed.', 'error')
          } else {
            Swal.fire('Verwijderd!', 'Server is verwijderd.', 'success')
            this.getServers()
          }
        }
      })
    },
  },
  middleware: 'router-auth',
  async created() {
    this.getServers()
  },
}
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-lg-left mt-3 mt-lg-0">
                  <a
                    class="btn text-white btn-danger"
                    href="javascript: void(0);"
                    @click="showmodal = true"
                  >
                    <i class="mdi mdi-plus-circle mr-1"></i> {{ $t('pages.servers.addnew') }}
                  </a>
                </div>
              </div>
              <!-- end col-->
            </div>
            <!-- end row -->
          </div>
          <!-- end card-box -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div
        v-for="server in servers"
        :key="server.id"
        class="col-xl-4 col-sm-6"
      >
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="avatar-md mr-3">
                <div class="avatar-title bg-light rounded-circle">
                  <i class="ri-server-line h2 text-primary"></i>
                </div>
              </div>
              <div class="media-body">
                <h4 class="my-1">
                  {{
                    server.tag
                  }}
                  <b-badge
                    v-if="server.isActive"
                    variant="success"
                  >Actief</b-badge>
                  <b-badge
                    v-else
                    variant="warning"
                  >Inactief</b-badge>
                </h4>
                <p class="text-muted text-truncate mb-0">

                  Ip: {{ server.ipaddress }} <br />Port: {{ server.port }} <br /> Type: {{ server.serverType.charAt(0).toUpperCase() + server.serverType.slice(1) }}
                </p>
              </div>

              <b-dropdown
                right
                toggle-class="text-body p-0"
                variant="black"
              >
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical font-20"></i>
                </template>
                <b-dropdown-item @click="editServer(server.id)"><i class="mdi mdi-square-edit-outline font-20"></i> Edit</b-dropdown-item>
                <b-dropdown-item
                  @click="activateServer(server.id, false)"
                  v-if="server.isActive"
                ><i class="mdi mdi-pause-circle font-20"></i> Deactivate</b-dropdown-item>
                <b-dropdown-item
                  @click="activateServer(server.id, true)"
                  v-if="!server.isActive"
                ><i class="mdi mdi-play-circle font-20"></i> Activate</b-dropdown-item>
                <b-dropdown-item @click="confirmDelete(server.id)"><i class="mdi mdi-delete font-20"></i> Delete</b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="server.serverType === 'multipress' && server.info">
              <hr />

              <div class="text-muted">
                <div class="row">
                  <div class="col-6">
                    <div>
                      <h5 class="mb-sm-0">v. {{ server.info.version }} on {{ server.info.platform }}</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <h5 class="mb-sm-0">{{ server.info.program }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-1"
      v-model="showmodal"
      :title="$t('pages.servers.addserver')"
      header-close-variant="light"
      title-class="font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">{{ $t('pages.servers.tag') }}</label>
          <input
            id="name"
            v-model="server.tag"
            type="text"
            class="form-control"
            placeholder="Enter name"
            :class="{ 'is-invalid': submitted && $v.server.tag.$error }"
          />
          <div
            v-if="submitted && !$v.server.tag.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.tagrequired') }}
          </div>
        </div>
        <div class="form-group">
          <label for="serverType">{{ $t('pages.servers.servertype') }}</label>
          <select
            id="serverType"
            class="form-control"
            v-model="server.serverType"
            :class="{ 'is-invalid': submitted && $v.server.serverType.$error }"
            @change="setPort"
          >
            <option selected>{{ $t('pages.servers.choose') }}</option>
            <option value="multipress">MultiPress</option>
            <option value="switch">Enfocus Switch</option>
          </select>
          <div
            v-if="submitted && !$v.server.serverType.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.servertyperequired') }}
          </div>
        </div>
        <div class="form-group">
          <label for="ip">{{ $t('pages.servers.ip') }}</label>
          <input
            id="ip"
            v-model="server.ipaddress"
            type="text"
            class="form-control"
            placeholder="Enter IP address"
            :class="{ 'is-invalid': submitted && $v.server.ipaddress.$error }"
          />
          <div
            v-if="submitted && !$v.server.ipaddress.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.iprequired') }}
          </div>
          <div
            v-if="submitted && !$v.server.ipaddress.ipAddress"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.validip') }}
          </div>
        </div>
        <div class="form-group">
          <label for="port">{{ $t('pages.servers.port') }}</label>
          <input
            id="port"
            v-model="server.port"
            type="text"
            class="form-control"
            placeholder="Enter port"
            :class="{ 'is-invalid': submitted && $v.server.port.$error }"
          />
          <div
            v-if="submitted && !$v.server.port.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.portrequired') }}
          </div>
          <div
            v-if="submitted && !$v.server.port.numeric"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.portvalid') }}
          </div>
        </div>
        <div class="form-group">
          <label for="username">{{ $t('pages.servers.username') }}</label>
          <input
            id="username"
            v-model="server.username"
            type="text"
            class="form-control"
            placeholder="Enter username"
            :class="{ 'is-invalid': submitted && $v.server.username.$error }"
          />
          <div
            v-if="submitted && !$v.server.username.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.usernamerequired') }}
          </div>
          <div
            v-if="submitted && !$v.server.username.numeric"
            class="invalid-feedback"
          >
          </div>
        </div>
        <div class="form-group">
          <label for="password">{{ $t('pages.servers.password') }}</label>
          <input
            id="password"
            v-model="server.password"
            type="password"
            class="form-control"
            placeholder="Enter password"
            :class="{ 'is-invalid': submitted && $v.server.password.$error }"
          />
          <div
            v-if="submitted && !$v.server.password.required"
            class="invalid-feedback"
          >
            {{ $t('pages.servers.passwordrequired') }}
          </div>
        </div>
        <div class="form-group">
          <label for="active">{{ $t('pages.servers.active') }}</label><br />
          <switches
            v-model="server.isActive"
            v-if="!server.isActive"
            color="warning"
            class="ml-1"
          ></switches>
          <switches
            v-model="server.isActive"
            v-else
            color="success"
            class="ml-1"
          ></switches>
        </div>
        <div class="text-right">
          <button
            v-if="!server.id && !loading"
            type="submit"
            class="btn btn-success"
          >{{ $t('pages.servers.add') }}</button>
          <button
            v-if="server.id && !loading"
            type="submit"
            class="btn btn-success"
          >{{ $t('pages.servers.update') }}</button>
          <b-button
            variant="primary"
            disabled
            class="ml-1"
            v-if="loading"
          >
            <b-spinner small></b-spinner>&nbsp;&nbsp; {{ $t('pages.servers.save') }}
          </b-button>
          <b-button
            class="ml-1"
            variant="danger"
            @click="hideModal"
          >{{ $t('pages.servers.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- Modal  -->
  </Layout>
</template>
