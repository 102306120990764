<script>
import Swal from 'sweetalert2'
import api from '../../../../../helpers/axios'

export default {
  data() {
    return {
      files: [],
      totalSize: 0,
      errors: [],
      uploadPercentage: 0,
      shake: false,
      hover: false,
      isUploading: false,
      isProfilecheck: false,
      isProfilecheckFinished: false,
      isFinished: false,
      hover: false,
    }
  },
  props: {
    attribute: Object,
  },
  methods: {
    async uploadFile() {
      this.isUploading = true

      // Create formdata with files from upload component
      let formData = new FormData()
      // If there are multiple files, add them to the formData
      this.files.forEach(file => {
        formData.append('uploads', file)
      })

      // Send files to server
      const sent = await this.sendFiles(formData)
      if (!sent) {
        this.$toast.warning('Uploaden niet gelukt.', {
          position: 'top-right',
          duration: 4000,
        })
        return
      }
      this.removeFiles()

      this.$toast.success('Uploaden voltooid.', {
        position: 'top-right',
        duration: 4000,
      })

      // TODO: full profile check based on product
      // await this.profileCheck()
    },
    async sendFiles(formData) {
      console.log(this.attribute.upload.id)
      // In order to show upload progress, this api call cannot be imported from a service
      const result = await api().post(`uploads/${this.attribute.upload.id}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
        },
      })

      if (result.status === 201) {
        await this.getUpload()
        this.isUploading = false
        this.$emit('changed', true)
        this.uploadPercentage = 0
        return true
      }
      return false
    },
    async removeUploads() {
      Swal.fire({
        title: 'Uploads verwijderen?',
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijderen!',
        cancelButtonText: 'Annuleren',
      }).then(async result => {
        if (result.value) {
          const deleted = await api().delete(`uploads/${this.attribute.upload.id}`)
          if (deleted.status === 204) {
            await this.getUpload()
            this.$emit('changed', true)
            Swal.fire('Verwijderd!', 'Uploads met succes verwijderd', 'success')
            this.isUploading = false
            this.removeFiles()
            return true
          }
        }

        return false
      })
    },
    async profileCheck() {},
    async getUpload() {
      const upload = await api().get(`uploads/${this.attribute.upload.id}`)
      if (upload.status === 200) {
        this.attribute.upload = upload.data
      }
    },
    handleFileDrop(e) {
      for (let i = 0; i < e.target.files.length; i += 1) {
        const file = e.target.files[i]
        const filename = file.name
        const ext = filename.substr(filename.lastIndexOf('.') + 1)
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'pdf') {
          this.files.push(file)
        } else {
          this.errors.push(`${filename} ${this.$t('pages.order-detail.notallowed')}`)
          this.shake = true
          setTimeout(() => {
            this.shake = false
          }, 1000)
          this.$refs.fileInput.value = ''
        }
      }
      this.calculateTotalSize()
    },
    calculateTotalSize() {
      for (let i = 0; i < this.files.length; i += 1) {
        let file = this.files[i]
        this.totalSize = this.totalSize + file.size
      }
    },
    removeFiles() {
      this.files = []
      this.totalSize = 0
      this.errors = []
      this.$refs.fileInput.value = ''
    },
    removeFromErrorList(index) {
      this.errors.splice(index, 1)
    },
  },
  computed: {
    niceList() {
      let list
      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i]
        if (!list) {
          list = file.name
        } else {
          list = `${list} | ${file.name}`
        }
      }
      return list
    },
  },
  middleware: 'router-auth',
}
</script>

<template>
  <div>
    <transition name="fade">
      <form>
        <b-progress show-value :max="100" :value="uploadPercentage" v-if="isUploading"></b-progress>

        <!-- <b-button variant="secondary" disabled class="ml-1 mr-1" v-if="isProfilecheck"> <b-spinner small></b-spinner>&nbsp;&nbsp;Checking PDF... </b-button> -->

        <div
          class="fileupload btn btn-secondary ml-1"
          :class="{ shake: shake, hover: hover }"
          v-if="!isUploading && !isFinished && !attribute.upload.files.length"
          @dragover="hover = true"
          @dragleave="hover = false"
          @drop="hover = false"
        >
          <span v-if="!files.length"> <i class="mdi mdi-cloud-upload mr-1"></i>{{ $t('pages.order-detail.upload-button') }}</span>
          <span v-else> <i class="mdi mdi-cloud-upload mr-1"></i>{{ niceList }} </span>
          <input type="file" ref="fileInput" multiple class="upload" accept="image/x-png,image/gif,image/jpeg,application/pdf" @change="handleFileDrop" />
        </div>

        <div class="btn btn-danger" v-if="attribute.upload.files.length && !isUploading && !isProfilecheck">
          <span @click="removeUploads"> <i class="mdi mdi-delete mr-1"></i>{{ $t('pages.order-detail.remove-upload') }}</span>
        </div>

        <div v-if="files.length && !attribute.upload.files.length && !isUploading" class="mt-1">
          <span class="btn btn-success upload ml-1" @click="uploadFile()"><i class="mdi mdi-upload"></i> Upload</span>
          <span class="btn btn-danger upload ml-1" @click="removeFiles()"><i class="mdi mdi-close-circle-outline"></i></span>
          <b-badge v-if="totalSize" class="badge-outline-secondary ml-1 font-12">{{ (totalSize / 1000 / 1000).toFixed(2) }} MB</b-badge>
        </div>

        <div v-if="errors.length">
          <b-alert show variant="danger" class="mt-1 pa-1" v-for="(error, index) in errors" :key="index">
            <i class="mdi mdi-block-helper mr-2"></i>{{ error }}
            <span class="remove-error float-right" @click="removeFromErrorList(index)"><i class="mdi mdi-close-circle-outline"></i></span>
          </b-alert>
        </div>
      </form>
    </transition>
  </div>
</template>

<style scoped>
.hover {
  background-color: #424e5a;
  color: #fff;
  border-color: #fff;
}
.remove-error:hover {
  cursor: pointer;
}
.upload {
  display: inline-block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
