<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../../app.config'

import { multipressService } from '../../../../helpers/multipress/orders.service'

/**
 * Orders component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: 'Offertes',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      quotationsData: [],
      title: 'Offertes',
      items: [
        {
          text: 'Pressfer',
        },
        {
          text: 'MultiPress',
        },
        {
          text: 'Offertes',
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 30, 50, 100],
      filter: null,
      filterOn: ['quotation_status'],
      sortBy: 'job_number',
      sortDesc: true,
      fields: [
        {
          key: 'check',
          label: '',
        },
        {
          key: 'quotation_number',
          label: 'Quotation number',
        },
        {
          key: 'description',
          label: 'Order description',
        },
        {
          key: 'quotation_date',
          label: 'Quotation date',
        },
        {
          key: 'job_status',
          label: 'Order Status',
        },
        'action',
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.quotationsData.length
    },
  },
  async created() {
    const orders = await multipressService.getOrders(0, 0, 0, 1)
    this.quotationsData = orders.data.data.jobs.filter((job) => {
      return job.job_number === 0
    })
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_length"
                  class="dataTables_length"
                >
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>&nbsp;Orders
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                table-class="table table-centered w-100"
                thead-tr-class="bg-light"
                :items="quotationsData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`contacusercheck${data.item.id}`"
                    />
                    <label
                      class="custom-control-label"
                      :for="`contacusercheck${data.item.id}`"
                    ></label>
                  </div>
                </template>

                <template v-slot:cell(quotation_number)="data">
                  <router-link
                    :to="`/multipress/order-detail/${data.item.id}`"
                    class="text-body font-weight-medium"
                    v-if="data.item.quotation_number"
                  >{{ data.item.quotation_number }}</router-link>
                </template>
                <template v-slot:cell(status)="data">
                  <span
                    class="badge badge-soft-success"
                    :class="{
                      'badge-soft-warning':
                        data.item.job_status === 'Nieuwe order',
                      'badge-soft-danger':
                        data.item.job_status === 'Payment Failed',
                    }"
                  >
                    {{ data.item.job_status }}</span>
                </template>
                <template v-slot:cell(description)="data">
                  {{ data.item.description }}
                </template>
                <template v-slot:cell(quotation_date)="data">
                  {{ new Date(data.item.quotation_date).toLocaleDateString('nl-NL') }}
                </template>
                <template v-slot:cell(job_status)="data">
                  <span
                    class="badge"
                    :class="{
                      'badge-warning': data.item.quotation_status === 'Offerte uitgebracht',
                      'badge-success': data.item.quotation_status === 'Order',
                      'badge-info': data.item.quotation_status === 'Whatever',
                    }"
                  >
                    {{ data.item.quotation_status }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <router-link
                        :to="`/multipress/order-detail/${data.item.id}`"
                        class="text-body font-weight-medium"
                      ><i class="mdi mdi-eye-outline font-20"></i></router-link>
                    </li>

                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
td a {
  text-decoration: underline;
}
td a:hover {
  color: #79d4b9 !important;
}
</style>