import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import simplebar from 'simplebar-vue'
import VueTour from 'vue-tour'
import vco from 'v-click-outside'
import i18n from './i18n'

import router from './router'
import store from '@/state/store'

import VueApexCharts from 'vue-apexcharts'
import VueStringFilter from 'vue-string-filter'
import Lightbox from 'vue-easy-lightbox'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

Vue.use(vco)

// As a plugin
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import VueDraggable from 'vue-draggable'
Vue.use(VueDraggable)
Vue.use(VueQuillEditor)
Vue.use(VueMask)

import VueSlideBar from 'vue-slide-bar'

Vue.component('VueSlideBar', VueSlideBar)

Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(require('vue-chartist'))
Vue.component('simplebar', simplebar)
Vue.use(VueStringFilter)
Vue.use(VueTour)
Vue.use(Lightbox)
Vue.use(VueToast)

import '@/assets/scss/app.scss'

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.authRequired)) {
//     next()
//   } else {
//     console.log('Auth not required')
//     console.log(store.dispatch('auth/validateUser'))
//     next() // make sure to always call next()!
//   }
// })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
