<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created() {
    this.validateUser
  },
  computed: {
    ...mapActions('auth', ['validateUser']),
  },
}
</script>
