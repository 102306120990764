<script>
// import { ordersData } from './data'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../../app.config'
import Swal from 'sweetalert2'
import { jobService } from '../../../../helpers/jobs/jobs.service'

/**
 * Orders component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: 'Orders',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      ordersData: [],
      isMailing: false,
      rowIndex: '',
      title: 'Orders',
      items: [
        {
          text: 'Deltabach',
        },
        {
          text: 'MultiPress',
        },
        {
          text: 'Orders',
          active: true,
        },
      ],
      isSynchronizing: false,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20],
      totalPages: 1,
      filter: null,
      filterOn: [],
      sortBy: 'jobNumber',
      sortDesc: true,
      searchString: null,
      fields: [
        // {
        //   key: 'check',
        //   label: '',
        // },
        {
          key: 'isAgreed',
          label: 'Aangeleverd',
        },
        {
          key: 'jobNumber',
          label: 'Ordernummer',
        },
        {
          key: 'company',
          label: 'Bedrijfsnaam',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'reference',
          label: 'Referentie',
        },
        {
          key: 'jobDate',
          label: 'Orderdatum',
        },
        {
          key: 'deliveryDate',
          label: 'Leverdatum',
        },
        {
          key: 'status',
          label: 'Job Status',
        },
        'action',
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },
  async mounted() {
    await this.getJobs()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async resetFilter() {
      this.searchString = null
      this.currentPage = 1
      this.getJobs()
    },
    async findJobs() {
      if (this.searchString.length >= 3) {
        const result = await jobService.findJob(this.searchString)
        this.currentPage = result.data.currentPage
        this.totalRows = result.data.total
        this.totalPages = result.data.totalPages
        this.ordersData = result.data.jobs
      }
    },
    async getJobs() {
      // console.log(this.currentPage, this.perPage)
      const jobs = await jobService.getJobs(this.perPage, this.currentPage)
      this.totalRows = jobs.data.total
      this.totalPages = jobs.data.totalPages
      this.ordersData = jobs.data.jobs
    },
    async updatePage(currentPage) {
      this.currentPage = currentPage
      this.getJobs()
    },
    async syncJobs() {
      this.$toast.info('Order synchronisatie gestart.', {
        position: 'top-right',
        duration: 4000,
      })
      this.isSynchronizing = true
      await jobService.syncJobs()
      this.getJobs()
      this.isSynchronizing = false
      this.$toast.success('Order synchronisatie gelukt.', {
        position: 'top-right',
        duration: 4000,
      })
    },
    async emailJob(id, rowIndex) {
      try {
        this.isMailing = true
        this.rowIndex = rowIndex

        let result

        Swal.fire({
          title: 'Bestelbevestiging met, of zonder aanlever mogelijkheid?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Met',
          denyButtonText: 'Zonder',
          cancelButtonText: 'Niet versturen',
        }).then(async (result) => {
          // Confirmed -> with button
          if (result.isConfirmed) {
            result = await jobService.emailJob(id, true)
            // Denied -> without button
          } else if (result.isDenied) {
            result = await jobService.emailJob(id, false)
          }

          if (result.status === 200) {
            this.$toast.success('E-mail succesvol verstuurd.', {
              position: 'top-right',
              duration: 4000,
            })
          }

          await this.getJobs()
          this.isMailing = false
          this.rowIndex = 0
        })
      } catch (error) {
        this.$toast.error('E-mail niet verstuurd.', {
          position: 'top-right',
          duration: 4000,
        })
        this.isMailing = false
      }
    },
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Toon&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;Orders
                  </label>
                  <span class="ml-2">
                    <b-button variant="primary" class="ml-1" v-if="!isSynchronizing" @click="syncJobs">Synchroniseer orders</b-button>
                    <b-button variant="primary" disabled class="ml-1" v-if="isSynchronizing">
                      <b-spinner small></b-spinner>&nbsp;&nbsp;Orders synchroniseren...
                    </b-button>
                  </span>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <!-- <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Filter:
                    <b-form-input v-model="filter" type="search" placeholder="Filter..." class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div> -->
                <div id="table-search" class="text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Filter:
                    <b-form-input
                      v-model="searchString"
                      type="search"
                      placeholder="Filter..."
                      class="form-control form-control-sm ml-2"
                      @input="findJobs"
                    ></b-form-input>
                  </label>
                  <b-button @click="resetFilter" variant="primary" class="btn-sm ml-1">Herstel</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                table-class="table table-centered w-100"
                thead-tr-class="bg-light"
                :items="ordersData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                    <input type="checkbox" class="custom-control-input" :id="`contacusercheck${data.item.id}`" />
                    <label class="custom-control-label" :for="`contacusercheck${data.item.id}`"></label>
                  </div>
                </template> -->
                <template v-slot:cell(company, contact)="data"> {{ data.item.company }} {{ data.item.contact }} </template>
                <template v-slot:cell(jobNumber)="data">
                  <router-link :to="`/multipress/order-detail/${data.item.id}`" class="text-body font-weight-medium">{{ data.item.jobNumber }}</router-link>
                </template>
                <template v-slot:cell(isAgreed)="data">
                  <i class="mdi mdi-thumb-up" style="color: #79d4b9; font-size: 1.3em" v-if="data.item.isAgreed"></i>
                  <i class="mdi mdi-thumb-down" v-if="!data.item.isAgreed" style="font-size: 1.3em"></i>
                </template>
                <template v-slot:cell(reference)="data">
                  {{ data.item.reference }}
                </template>
                <template v-slot:cell(jobDate)="data">
                  {{ new Date(data.item.jobDate).toLocaleDateString('nl-NL') }}
                </template>
                <template v-slot:cell(deliveryDate)="data">
                  {{ new Date(data.item.deliveryDate).toLocaleDateString('nl-NL') }}
                </template>
                <template v-slot:cell(status)="data">
                  <span class="badge badge-outline-info font-12"> {{ data.item.status }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <router-link :to="`/multipress/order-detail/${data.item.id}`" class="text-body"><i class="mdi mdi-eye-outline font-20"></i></router-link>
                    </li>
                    &nbsp;&nbsp;
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="action-icon" @click="emailJob(data.item.id, data.index)" v-if="data.item.isEmailed">
                        <i class="mdi mdi-email-outline" style="color: green"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="action-icon"
                        @click="emailJob(data.item.id, data.index)"
                        v-if="!data.item.isEmailed && data.item.email"
                      >
                        <b-tooltip :target="data.item.id + '_email'">E-mail wordt verstuurd naar: {{ data.item.email }}.</b-tooltip>
                        <i class="mdi mdi-email-outline" :id="data.item.id + '_email'" style="color: orange"></i
                      ></a>
                      <a href="javascript:void(0)" class="action-icon" v-if="!data.item.isEmailed && !data.item.email">
                        <b-tooltip :target="data.item.id">Let op: Geen e-mail adres beschikbaar.</b-tooltip>
                        <i class="mdi mdi-email-outline" :id="data.item.id" style="color: grey"></i>
                      </a>
                      <b-spinner variant="primary" style="width: 1rem; height: 1rem" v-if="isMailing && data.index === rowIndex"></b-spinner>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      first-text="Eerste"
                      prev-text="Vorige"
                      next-text="Volgende"
                      last-text="Laatste"
                      limit="7"
                      @input="updatePage(currentPage)"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
td a {
  text-decoration: underline;
}
td a:hover {
  color: #79d4b9 !important;
}
</style>
