<script>
import { layoutComputed } from '@/state/helpers'

import Vertical from './vertical'

/**
 * Main layout
 */
export default {
  components: {
    Vertical,
  },
  data() {
    return {}
  },
  computed: {
    ...layoutComputed,
  },
}
</script>

<template>
  <div>
    <!-- Begin page -->
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>
    <!-- END layout-wrapper -->
  </div>
</template>
