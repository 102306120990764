import api from '../axios'

async function login(email, password) {
  const data = { email, password }
  const user = await api().post('users/login', data)

  if (user.data.status === 'success' && user.data.token) {
    api().defaults.headers.common['Authorization'] = `Bearer ${user.data.token}`
    localStorage.setItem('user', JSON.stringify(user.data.user))
    localStorage.setItem('pressferToken', user.data.token)
    return user.data.user
  }
}

/**
 * Validates the token against the API
 * @param { String } token
 */
async function validateToken(token) {
  api().defaults.headers.common['Authorization'] = `Bearer ${token}`
  const result = await api().get('users/validate')
  return result
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(`/users/register`, requestOptions).then(handleResponse)
}

async function getUsers() {
  return api().get('users')
}

async function getUser(id) {
  return api().get(`users/${id}`)
}

async function deleteUser(id) {
  return api().delete(`users/${id}`)
}

async function createUser(user) {
  return api().post('users', JSON.stringify(user))
}

async function changeUser(user) {
  return api().patch(`users/${user.id}`, JSON.stringify(user))
}

export const userService = {
  login,
  getUsers,
  getUser,
  createUser,
  changeUser,
  deleteUser,
  register,
  validateToken,
}
