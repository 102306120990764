<script>
import { layoutComputed } from '@/state/helpers'

import Sidebar from '@/components/Sidebar'
import Topbar from '@/components/Topbar'
import Footer from '@/components/Footer'

/**
 * Vertical layout
 */
export default {
  components: {
    Sidebar,
    Topbar,
    Footer,
  },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    document.body.removeAttribute('data-layout-mode')
    document.body.removeAttribute('data-sidebar-showuser')
  },
  methods: {
    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed
      if (this.isMenuCondensed) document.body.setAttribute('data-sidebar-size', 'condensed')
      else document.body.removeAttribute('data-sidebar-size', 'condensed')

      if (window.screen.width >= 992) {
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
      } else {
        document.body.setAttribute('data-sidebar-size', 'default')
        document.body.classList.toggle('sidebar-enable')
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
      }
    },
  },
}
</script>

<template>
  <!-- Begin page -->
  <div id="wrapper">
    <Sidebar :type="leftSidebarType" :width="layoutWidth" :size="leftSidebarSize" :menu="menuPosition" :topbar="topbar" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <Topbar />
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
