import Vue from 'vue'
import axios from 'axios'
import store from '../state/store'
import router from '../router/index'

import VueToast from 'vue-toast-notification'
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEV_API : process.env.VUE_APP_PROD_API

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// Interceptor for 401 error
instance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    let message = error.response.data.message

    if (error.response.status === 400) {
      if (error.response.data.message === 'Duplicate entry') {
        message = 'Record bestaat al'
      }
      if (error.response.data.message.startsWith('E11000')) {
        message = 'Record bestaat al.'
      }

      Vue.$toast.warning(message, {
        position: 'top-right',
        duration: 4000,
      })
    }

    if (error.response.status === 401) {
      Vue.$toast.warning('Uw sessie is verlopen / toegang niet toegestaan.. Log aub opnieuw in.', {
        position: 'top-right',
        duration: 4000,
      })
      store.dispatch('auth/logout')
      router.push('/login')
    }

    if (error.response.status === 404) {
      if (error.response.data.message.startsWith('Could not reach') || error.response.data.message.startsWith('Server not found')) {
        message = 'Kan MultiPress server niet bereiken. Controleer ip adres en poort of kijk of de MultiPress server aan staat.'
      }

      Vue.$toast.error(message, {
        position: 'top-right',
        duration: 4000,
      })
    }

    if (error.response.status === 500) {
      Vue.$toast.error('Kan de server niet bereiken of er is een kritieke fout opgetreden. Neem contact op met uw beheerder.', {
        position: 'top-right',
        duration: 3000,
      })
    }

    return Promise.reject({
      status: error.response.status,
      message,
    })
  },
)

export default () => {
  return instance
}
