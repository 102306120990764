<script>
/**
 * Footer Component
 */
export default {}
</script>

<template>
  <!-- Footer Start -->
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">{{ new Date().getFullYear() }} &copy; <a href="https://www.grafistore.nl" target="_blank">GrafiStore&trade;</a></div>
        <div class="col-md-6">
          <div class="text-md-right footer-links d-none d-sm-block">
            <a href="https://www.grafistore.nl/klantenservice/" target="_blank">Support</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end Footer -->
</template>
