import api from '../axios'

const getConfig = async () => {
  return await api().get('config')
}

/**
 *
 * @param {Object} data
 * @returns AxiosResponse
 */
const updateConfig = async data => {
  return await api().patch('config', data)
}

const deleteEmail = async id => {
  return await api().patch(`config/email/delete/${id}`)
}

export const configService = {
  getConfig,
  updateConfig,
  deleteEmail,
}
