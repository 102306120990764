<script>
// import { ordersData } from './data'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../../app.config'
import Switches from 'vue-switches'
import { profileService } from '../../../../helpers/pdftoolbox/profile.service'
import { required } from 'vuelidate/lib/validators'

import Swal from 'sweetalert2'
/**
 * Orders component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Switches,
  },
  page: {
    title: 'Orders',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  validations: {
    pdfProfile: {
      name: {
        required,
      },
      description: {
        required,
      },
      profileType: {
        required,
      },
    },
  },
  data() {
    return {
      profilesData: [],
      pdfProfile: {
        name: null,
        description: null,
        profileType: 'fix',
        isDefault: false,
      },
      title: 'Profielen',
      items: [
        {
          text: 'Pressfer',
        },
        {
          text: 'pdfToolbox',
        },
        {
          text: 'Profiles',
          active: true,
        },
      ],
      showmodal: false,
      loading: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 30, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Profile description',
        },
        {
          key: 'resource',
          label: 'Profile file',
        },
        {
          key: 'isDefault',
          label: 'Default profile',
        },
        {
          key: 'dictionary',
          label: 'Dictionary',
        },
        {
          key: 'version',
          label: 'Version',
        },
        'action',
      ],
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideModal() {
      this.resetPdfProfile()
      this.loading = false
      this.submitted = false
      this.showmodal = false
    },
    resetPdfProfile() {
      this.pdfProfile = {
        name: null,
        description: null,
        profileType: null,
        isDefault: false,
      }
    },
    async deleteProfile(id) {
      Swal.fire({
        title: `Profiel verwijderen?`,
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00c98e',
        cancelButtonColor: '#d33',
        background: '#2b2f3a',
        confirmButtonText: 'Ja, verwijderen!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await profileService.deleteProfile(id)
          if (deleted.status !== 204) {
            this.$toast.error('Verwijderen niet gelukt.', {
              position: 'top-right',
              duration: 3000,
            })
          } else {
            this.$toast.success('Profiel verwijderd.', {
              position: 'top-right',
              duration: 3000,
            })

            this.getProfiles()
          }
        }
      })
    },
    async createProfile() {
      const newProfile = await profileService.createProfile(this.pdfProfile)

      if (newProfile.status !== 201) {
        this.$toast.error(`Kon profiel niet aanmaken: ${newProfile.message}`, {
          position: 'top-right',
          duration: 3000,
        })
        return
      }

      this.$toast.success(`Profiel aangemaakt.`, {
        position: 'top-right',
        duration: 3000,
      })
      this.submitted = false
      this.loading = false
      this.showmodal = false
      this.resetPdfProfile()
      this.getProfiles()
    },
    async editPdfProfile(id) {
      const profile = await profileService.getProfile(id)
      if (profile.status !== 200) {
        Swal.fire('Oeps!', 'Profiel niet gevonden!', 'error')
        return
      }

      this.pdfProfile = profile.data.data
      this.showmodal = true
    },
    async savePdfProfile() {
      const result = await profileService.patchProfile(this.pdfProfile)

      if (result.status !== 200) {
        this.$toast.error('Profiel niet opgeslagen, er ging iets mis.', {
          position: 'top-right',
          duration: 3000,
        })
        return
      }

      this.$toast.success('Profiel opgeslagen', {
        position: 'top-right',
        duration: 3000,
      })
      this.loading = false
      this.submitted = false
      this.showmodal = false
      this.resetPdfProfile()
      this.getProfiles()
    },
    async getProfiles() {
      const profiles = await profileService.getProfiles()
      if (profiles.data.data) this.profilesData = profiles.data.data
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.profilesData.length
    },
  },
  async created() {
    this.getProfiles()
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <a class="btn text-white btn-danger" href="javascript: void(0);" @click="showmodal = true">
                  <i class="mdi mdi-plus-circle mr-1"></i> {{ $t('pages.profiles.addprofile') }}
                </a>
              </div>
              <!-- end col-->
            </div>
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;Profiles
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                table-class="table table-centered w-100"
                thead-tr-class="bg-light"
                :items="profilesData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                    <input type="checkbox" class="custom-control-input" :id="`contacusercheck${data.item.id}`" />
                    <label class="custom-control-label" :for="`contacusercheck${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <!-- <router-link :to="`/pdftoolbox/profile-details/${data.item.id}`" class="text-body font-weight-medium">{{ data.item.name }}</router-link> -->
                  {{ data.item.name }}
                </template>
                <template v-slot:cell(description)="data">
                  {{ data.item.description }}
                </template>
                <template v-slot:cell(resource)="data">
                  <b-badge class="badge-outline-primary p-1" v-if="data.item.resource">{{ $t('pages.pdftoolbox.present') }}</b-badge>
                </template>
                <template v-slot:cell(isDefault)="data">
                  <b-badge class="badge-outline-primary p-1" v-if="data.item.isDefault">{{ $t('pages.profiles.true') }}</b-badge>
                  <b-badge class="badge-outline-dark p-1" v-if="!data.item.isDefault">{{ $t('pages.profiles.false') }}</b-badge>
                </template>
                <template v-slot:cell(dictionary)="data">
                  <span v-for="dict in data.item.dictionary" :key="dict.language">{{ dict.language }} </span>
                </template>
                <template v-slot:cell(job_status)="data">
                  <span
                    class="badge"
                    :class="{
                      'badge-warning': data.item.job_status === 'Processing',
                      'badge-success': data.item.job_status === 'Delivered',
                      'badge-info': data.item.job_status === 'Whatever',
                      'badge-danger': data.item.job_status === 'Cancelled',
                    }"
                  >
                    {{ data.item.job_status }}</span
                  >
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <router-link :to="`/pdftoolbox/profile-details/${data.item.id}`" class="text-body"
                        ><i class="mdi mdi-eye-outline font-20"></i
                      ></router-link>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="editPdfProfile(data.item.id)" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" @click="deleteProfile(data.item.id)" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" v-model="showmodal" :title="$t('pages.profiles.addprofile')" header-close-variant="light" title-class="font-18" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">{{ $t('pages.profiles.name') }}</label>
          <input
            id="name"
            v-model="pdfProfile.name"
            type="text"
            class="form-control"
            placeholder="Enter name"
            :class="{ 'is-invalid': submitted && $v.pdfProfile.name.$error }"
          />
          <div v-if="submitted && !$v.pdfProfile.name.required" class="invalid-feedback">
            {{ $t('pages.profiles.namerequired') }}
          </div>
        </div>
        <div class="form-group">
          <label for="name">{{ $t('pages.profiles.description') }}</label>
          <input
            id="name"
            v-model="pdfProfile.description"
            type="text"
            class="form-control"
            placeholder="Enter name"
            :class="{ 'is-invalid': submitted && $v.pdfProfile.description.$error }"
          />
          <div v-if="submitted && !$v.pdfProfile.description.required" class="invalid-feedback">
            {{ $t('pages.profiles.descriptionrequired') }}
          </div>
        </div>

        <div class="form-group">
          <label for="name">{{ $t('pages.profiles.isdefault') }}</label>
          <div>
            <switches v-model="pdfProfile.isDefault" v-if="!pdfProfile.isDefault"></switches>
            <switches v-model="pdfProfile.isDefault" v-if="pdfProfile.isDefault" color="success" class="ml-1"></switches>
          </div>
        </div>

        <div class="text-right">
          <button v-if="!pdfProfile.id && !loading" @click="createProfile()" class="btn btn-success">{{ $t('pages.profiles.add') }}</button>
          <button v-if="pdfProfile.id && !loading" @click="savePdfProfile()" class="btn btn-success">{{ $t('pages.profiles.update') }}</button>
          <b-button variant="primary" disabled class="ml-1" v-if="loading">
            <b-spinner small></b-spinner>&nbsp;&nbsp; {{ $t('pages.profiles.save') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('pages.profiles.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
<style scoped>
td a {
  text-decoration: underline;
}
td a:hover {
  color: #79d4b9 !important;
}
</style>
