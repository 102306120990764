<script>
import Auth from '../../layouts/auth'
import appConfig from '../../../../app.config'

import { required, email } from 'vuelidate/lib/validators'

/**
 * Login component
 */
export default {
  page: {
    title: 'Pressfer - Login',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      email: null,
      password: null,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      languages: [
        {
          flag: require('@/assets/images/flags/dutch.jpg'),
          language: 'nl',
          title: 'Nederlands',
        },
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English',
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
    }
  },
  components: {
    Auth,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0
    },
  },
  created() {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const { email, password } = this
        if (email && password) {
          this.$store.dispatch('auth/login', {
            email,
            password,
          })
          this.$store.dispatch('notification/clear')
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale
      this.current_language = locale
      this.text = country
      this.flag = flag
    },
  },
}
</script>

<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="float-right border mb-2">
              <ul class="list-unstyled topnav-menu float-right mb-0">
                <li class="dropdown d-none d-lg-inline-block">
                  <b-nav-item-dropdown variant="white" class="d-none d-lg-inline-block" right toggle-class="header-item">
                    <template v-slot:button-content>
                      <img class :src="flag" alt="Header Language" height="16" />
                      {{ text }}
                    </template>
                    <b-dropdown-item
                      class="notify-item"
                      v-for="(entry, i) in languages"
                      :key="`Lang${i}`"
                      :value="entry"
                      @click="setLanguage(entry.language, entry.title, entry.flag)"
                      :link-class="{ active: entry.language === current_language }"
                    >
                      <img :src="`${entry.flag}`" alt="user-image" class="mr-1" height="12" />
                      <span class="align-middle">{{ entry.title }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </li>
              </ul>
            </div>
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/jouw-pdf.png" alt="" width="250" />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img src="@/assets/images/jouw-pdf.png" alt="" width="250" />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                {{ $t('pages.account.login.credentials') }}
              </p>
            </div>

            <form action="#" @submit.prevent="tryToLogIn">
              <b-alert :variant="notification.type" class="mt-3" v-if="notification.message" :show="notificationAutoCloseDuration" dismissible>{{
                notification.message
              }}</b-alert>

              <b-alert variant="danger" class="mt-3" v-model="isAuthError" :show="notificationAutoCloseDuration" dismissible>{{ authError }}</b-alert>
              <div class="form-group mb-3">
                <label for="emailaddress">{{ $t('pages.account.login.email') }}</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  id="emailaddress"
                  v-bind:placeholder="$t('pages.account.login.email-placeholder')"
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">{{ $t('pages.account.login.emailrequired') }}.</span>
                  <span v-if="!$v.email.email">{{ $t('pages.account.login.validemail') }}.</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">{{ $t('pages.account.login.password') }}</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="password"
                    type="password"
                    id="password"
                    class="form-control"
                    v-bind:placeholder="$t('pages.account.login.password-placeholder')"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text">
                      <span class="password-eye"></span>
                    </div>
                  </div>
                  <div v-if="submitted && !$v.password.required" class="invalid-feedback">{{ $t('pages.account.login.passwordrequired') }}.</div>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="checkbox-signin" checked />
                  <label class="custom-control-label" for="checkbox-signin">{{ $t('pages.account.login.remember') }}</label>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">Log In</button>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <router-link to="/forgot-password" class="text-muted ml-1">{{ $t('pages.account.login.forgotpassword') }}?</router-link>
            </p>
            <p class="text-muted">
              {{ $t('pages.account.login.noaccount') }}?
              <router-link to="/register" class="text-primary font-weight-medium ml-1">{{ $t('pages.account.login.signup') }}</router-link>
            </p>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>
