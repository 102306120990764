import api from '../axios'

/**
 *
 * @param {*} startdate
 * @param {*} stopdate
 * @param {*} type
 * :relation/:type/:startdate/:enddate
 */
const getOrders = async (relation = 0, startdate = 0, stopdate = 0, type = 0) => {
  return await api().get(`multipress/jobs/jobsquotations/${relation}/${type}/${startdate}/${stopdate}`)
}

const getOrder = async id => {
  return await api().get(`multipress/jobs/jobsquotationsdetails/${id}`)
}

export const multipressService = {
  getOrders,
  getOrder,
}
