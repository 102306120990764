<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../app.config'
import { userService } from '../../../helpers/authservice/user.service'
import { required, numeric, email } from 'vuelidate/lib/validators'

import Swal from 'sweetalert2'
import Switches from 'vue-switches'

/**
 * Contacts-list component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: 'Contacts-list',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      title: 'Gebruikers',
      items: [
        {
          text: 'Pressfer',
          href: '/',
        },
        {
          text: 'Config',
          href: '/',
        },
        {
          text: 'Gebruikers',
          active: true,
        },
      ],
      showmodal: false,
      submitted: false,
      loading: false,
      users: [],
      user: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        role: '',
      },
    }
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      password: {
        required,
      },
      role: {
        required,
      },
    },
  },
  methods: {
    async handleSubmit(e) {
      try {
        this.submitted = true

        this.$v.$touch()

        if (this.user.id) {
          const result = await userService.changeUser(this.user)
          Swal.fire('Gewijzigd!', 'Gebruiker is gewijzigd.', 'success')
        }

        if (!this.user.id) {
          const result = await userService.createUser(this.user)
          Swal.fire('Toegevoegd!', 'Gebruiker is toegevoegd.', 'success')
        }

        this.submitted = false
        this.showmodal = false
        this.getUsers()
      } catch (error) {
        console.error(error.message)
      }
    },
    async changeUser(id) {
      try {
        const result = await userService.getUser(id)
        this.user = result.data.data
        this.showmodal = true
      } catch (error) {
        console.error(error.message)
      }
    },
    async getUsers() {
      try {
        const result = await userService.getUsers()
        this.users = result.data.data
      } catch (error) {
        console.error(error.message)
      }
    },
    async deleteUser(id) {
      Swal.fire({
        title: `Gebruiker verwijderen?`,
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00c98e',
        cancelButtonColor: '#d33',
        background: '#2b2f3a',
        confirmButtonText: 'Ja, verwijderen!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await userService.deleteUser(id)

          if (!deleted) {
            Swal.fire('Oeps!', 'Verwijderen niet gelukt.', 'error')
          } else {
            Swal.fire('Verwijderd!', 'Gebruiker is verwijderd.', 'success')
            this.getUsers()
          }
        }
      })
    },
    cancelAddition() {
      this.user = {}
      this.submitted = false
      this.loading = false
      this.showmodal = false
    },
  },
  async mounted() {
    await this.getUsers()
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-sm-4">
        <a href="javascript:void(0);" @click="showmodal = true" class="btn btn-danger mb-2"
          ><i class="mdi mdi-plus-circle mr-2"></i> {{ $t('pages.users.addnew') }}</a
        >
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xl-3 col-sm-6" v-for="(item, index) in users" :key="index">
        <div class="text-center card">
          <div class="card-body">
            <b-dropdown class="float-right" variant="black" toggle-class="text-body p-0" right>
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical font-20"></i>
              </template>
              <b-dropdown-item @click="changeUser(item.id)"><i class="mdi mdi-square-edit-outline font-20"></i> {{ $t('pages.users.edit') }}</b-dropdown-item>
              <b-dropdown-item @click="deleteUser(item.id)"><i class="mdi mdi-delete font-20"></i> {{ $t('pages.users.delete') }}</b-dropdown-item>
            </b-dropdown>
            <img v-if="item.profile" :src="item.profile" class="rounded-circle img-thumbnail avatar-xl mt-1" alt="profile-image" />
            <div class="avatar-xl mx-auto mt-1" v-if="!item.profile">
              <div class="avatar-title bg-light rounded-circle">
                <i class="mdi mdi-account h1 m-0 text-body"></i>
              </div>
            </div>
            <h4 class="mt-3 mb-1">
              <router-link to="/contacts/profile" class="text-dark">{{ item.firstname }} {{ item.lastname }}</router-link>
            </h4>
            <p class="text-muted">
              {{ item.email }} <span> | </span>
              <span class="text-pink">
                {{ item.role }}
              </span>
            </p>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- MODAL ADD USER -->
      <b-modal id="modal-1" v-model="showmodal" :title="$t('pages.users.addnew')" header-close-variant="light" title-class="font-18" hide-footer>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="firstname">{{ $t('pages.users.firstname') }}</label>
            <input
              id="firstname"
              v-model="user.firstname"
              type="text"
              class="form-control"
              placeholder="Voornaam"
              :class="{ 'is-invalid': submitted && $v.user.firstname.$error }"
            />
            <div v-if="submitted && !$v.user.firstname.required" class="invalid-feedback">
              {{ $t('pages.users.firstnamerequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="lastname">{{ $t('pages.users.lastname') }}</label>
            <input
              id="lastname"
              v-model="user.lastname"
              type="text"
              class="form-control"
              placeholder="Achternaam"
              :class="{ 'is-invalid': submitted && $v.user.lastname.$error }"
            />
            <div v-if="submitted && !$v.user.lastname.required" class="invalid-feedback">
              {{ $t('pages.users.lastnamerequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="email">{{ $t('pages.users.email') }}</label>
            <input
              id="email"
              v-model="user.email"
              type="email"
              class="form-control"
              placeholder="E-mail"
              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
            />
            <div v-if="submitted && !$v.user.email.required" class="invalid-feedback">
              {{ $t('pages.users.emailrequired') }}
            </div>
            <div v-if="submitted && !$v.user.email.email" class="invalid-feedback">
              {{ $t('pages.users.validemail') }}
            </div>
          </div>

          <div class="form-group">
            <label for="password">{{ $t('pages.users.password') }}</label>
            <input
              id="password"
              v-model="user.password"
              type="password"
              class="form-control"
              placeholder="Wachtwoord"
              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
            />
            <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">
              {{ $t('pages.users.passwordrequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="serverType">{{ $t('pages.users.role') }}</label>
            <select id="role" class="form-control" v-model="user.role" :class="{ 'is-invalid': submitted && $v.user.role.$error }">
              <option value="user" selected>Gebruiker</option>
              <option value="admin">Admin</option>
            </select>
            <div v-if="submitted && !$v.user.role.required" class="invalid-feedback">
              {{ $t('pages.users.rolerequired') }}
            </div>
          </div>

          <div class="text-right">
            <button v-if="!user.id && !loading" type="submit" class="btn btn-success">{{ $t('pages.users.add') }}</button>
            <button v-if="user.id && !loading" type="submit" class="btn btn-success">{{ $t('pages.users.update') }}</button>
            <b-button variant="primary" disabled class="ml-1" v-if="loading"> <b-spinner small></b-spinner>&nbsp;&nbsp; {{ $t('pages.users.save') }} </b-button>
            <b-button class="ml-1" variant="danger" @click="cancelAddition">{{ $t('pages.users.cancel') }}</b-button>
          </div>
        </form>
      </b-modal>
      <!-- Modal  -->
    </div>
    <!-- end row -->
  </Layout>
</template>
