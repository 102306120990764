import api from '../axios'

const createProfile = async data => {
  return api().post('profiles', data)
}

const getProfiles = async () => {
  return api().get('profiles')
}

const getProfile = async id => {
  return api().get(`profiles/${id}`)
}

const patchProfile = async data => {
  return api().patch(`profiles/${data.id}`, data)
}

const deleteProfile = async id => {
  return api().delete(`profiles/${id}`)
}

export const profileService = { createProfile, getProfiles, getProfile, patchProfile, deleteProfile }
