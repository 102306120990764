<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../../app.config'

import { profileService } from '../../../../helpers/pdftoolbox/profile.service'
/**
 * Order-detail component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: 'Profiel details',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      pdfProfile: {},
      title: 'Profiel details',
      items: [
        {
          text: 'pdfToolbox',
        },
        {
          text: 'Profielen',
          to: '/pdftoolbox/profiles',
        },
        {
          text: 'Profiel details',
          active: true,
        },
      ],
    }
  },
  methods: {
    async getProfile() {
      const profile = await profileService.getProfile(this.$route.params.id)
      if (profile.status !== 200) {
        this.$toast.error('Profiel niet gevonden.', {
          position: 'top-right',
          duration: 3000,
        })
        return
      }

      this.pdfProfile = profile.data.data
    },
  },
  async created() {
    this.getProfile()
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <pre>{{ pdfProfile }}</pre>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
