import api from '../axios'

const getServers = async () => {
  const servers = await api().get('servers')
  if (!servers) {
    return
  }
  return servers.data
}

const getServer = async id => {
  return await api().get(`servers/${id}`)
}

const createServer = async server => {
  const { serverType, ipaddress, port, username, password, tag, isActive } = server
  return await api().post('servers', { serverType, ipaddress, port, username, password, tag, isActive })
}

const updateServer = async server => {
  const { serverType, ipaddress, port, username, password, tag, isActive } = server
  return await api().patch(`servers/${server.id}`, { serverType, ipaddress, port, username, password, tag, isActive })
}

const deleteServer = async id => {
  return await api().delete(`servers/${id}`)
}

/**
 * Check if server can be validated
 * @param { Number } id
 */
const validateServer = async id => {
  const reachable = await api().get(`servers/${id}/reachable`)
  if (reachable.status === 200) return true
  return false
}

export const serverService = {
  getServers,
  getServer,
  createServer,
  updateServer,
  deleteServer,
  validateServer,
}
