<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/Page-header'
import appConfig from '../../../../app.config'
import Switches from 'vue-switches'
import { configService } from '../../../helpers/pressferconfig/config.service'
import { required, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'

/**
 * Orders component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Switches,
    Multiselect,
  },
  page: {
    title: 'Email config',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      configData: { environment: '', testEmailToAddress: null, testEmailFromAddress: null },
      emailData: [],
      template: { id: null, emailFromAddress: null, holding: null, isDefault: false, service: null, template: null, url: null },
      showModal: false,
      loading: false,
      submitted: false,
      saveconfig: false,
      holdings: ['EasyPrint', 'Deltabach'],
      services: ['sendmail', 'gmail', 'smtp', 'office365'],
      environments: ['demo', 'live'],
      rowIndex: '',
      title: 'Email',
      items: [
        {
          text: 'Pressfer',
        },
        {
          text: 'Config',
        },
        {
          text: 'Email',
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [30, 60, 100],
      filter: null,
      filterOn: [],
      sortBy: 'holding',
      sortDesc: true,
      fields: [
        // {
        //   key: 'check',
        //   label: '',
        // },
        {
          key: 'holding',
          label: 'Organisatie',
        },
        {
          key: 'service',
          label: 'Service',
        },
        {
          key: 'emailFromAddress',
          label: 'Versturen als',
        },
        'action',
      ],
    }
  },
  validations: {
    template: {
      holding: {
        required,
      },
      service: {
        required,
      },
      template: {
        required,
      },
      url: {
        required,
      },
      emailFromAddress: {
        required,
        email,
      },
    },
    configData: {
      testEmailToAddress: {
        required,
        email,
      },
      testEmailFromAddress: {
        required,
        email,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.emailData.length
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getConfig() {
      const configData = await configService.getConfig()
      if (configData.status !== 200) {
        this.$toast.error('Geen e-mail configuratie gevonden.', {
          position: 'top-right',
          duration: 3000,
        })
        return
      }
      this.configData = {
        id: configData.data.data.email[0].id,
        environment: configData.data.data.email[0].environment,
        testEmailToAddress: configData.data.data.email[0].testEmailToAddress,
        testEmailFromAddress: configData.data.data.email[0].testEmailFromAddress,
      }
      this.emailData = configData.data.data.email[0].template
      this.totalRows = configData.data.data.email[0].template.length
    },
    async saveConfig(e) {
      this.loading = true
      this.saveconfig = true

      // stop here if form is invalid
      this.$v.$touch()

      const result = await configService.updateConfig(this.configData)

      if (result.status !== 200) {
        this.$toast.error('Email instellingen niet opgeslagen.', {
          position: 'top-right',
          duration: 3000,
        })
        return
      }

      this.$toast.success('Email instellingen opgeslagen', {
        position: 'top-right',
        duration: 3000,
      })

      if (this.configData.environment === 'live') {
        this.$toast.warning('Let op: U stuurt nu email naar uw klanten!', {
          position: 'top-right',
          duration: 3000,
        })
      }

      if (this.configData.environment === 'demo') {
        this.$toast.warning('Let op: U stuurt nu geen email naar uw klanten!', {
          position: 'top-right',
          duration: 3000,
        })
      }

      this.saveconfig = false
      this.loading = false
    },
    async addTemplate(e) {
      this.loading = true
      this.submitted = true

      // stop here if form is invalid
      this.$v.$touch()

      const result = await configService.updateConfig(this.template)

      if (result.status !== 200) {
        this.$toast.error('Profiel niet toegevoegd', {
          position: 'top-right',
          duration: 3000,
        })
        return
      }
      this.$toast.success('Profiel opgeslagen.', {
        position: 'top-right',
        duration: 3000,
      })
      this.showModal = false
      this.submitted = false
      this.loading = false
      this.template = {}
      this.getConfig()
    },
    async editTemplate(id) {
      const email = this.emailData.find((email) => {
        return email.id === id
      })

      this.template = email
      this.showModal = true
    },
    async deleteTemplate(id) {
      Swal.fire({
        title: `Email template verwijderen?`,
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00c98e',
        cancelButtonColor: '#d33',
        background: '#2b2f3a',
        confirmButtonText: 'Ja, verwijderen!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await configService.deleteEmail(id)

          console.log(deleted)

          if (deleted.status !== 204) {
            this.$toast.error('Email template niet verwijderd.', {
              position: 'top-right',
              duration: 3000,
            })
            return
          }

          this.$toast.success('Email template verwijderd.', {
            position: 'top-right',
            duration: 3000,
          })

          await this.getConfig()
        }
      })
    },
    hideModal() {
      this.showModal = false
      this.template = {}
      this.submitted = false
    },
  },
  async mounted() {
    await this.getConfig()
  },
  middleware: 'router-auth',
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4 col-md-4">
                <a class="btn text-white btn-danger" href="javascript: void(0);" @click="showModal = true">
                  <i class="mdi mdi-plus-circle mr-1"></i> {{ $t('pages.config.addmailtemplate') }}
                </a>
              </div>
              <div class="col-sm-8 col-md-8">
                <b-form inline @submit.prevent="saveConfig">
                  <b-form-group label-for="staticEmail2">
                    <multiselect
                      v-model="configData.environment"
                      :options="environments"
                      style="text-transform: capitalize; max-width: 100%; margin-top: 2px"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-for="inputEmailFrom" class="mx-sm-3" v-if="configData.environment === 'demo'">
                    <b-form-input
                      type="text"
                      id="inputEmailFrom"
                      placeholder="Email van"
                      v-model="configData.testEmailFromAddress"
                      :class="{ 'is-invalid': saveconfig && $v.configData.testEmailFromAddress.$error }"
                    ></b-form-input>
                    <div v-if="saveconfig && $v.configData.testEmailFromAddress.$error" class="invalid-feedback">
                      <span v-if="!$v.form.email.required">This value is required.</span>
                      <span v-if="!$v.form.email.email">This value should be a valid email.</span>
                    </div>
                  </b-form-group>
                  <b-form-group label-for="inputEmailTo" class="mx-sm-3" v-if="configData.environment === 'demo'">
                    <b-form-input
                      type="text"
                      id="inputEmailTo"
                      placeholder="Email naar"
                      v-model="configData.testEmailToAddress"
                      :class="{ 'is-invalid': saveconfig && $v.configData.testEmailToAddress.$error }"
                    ></b-form-input>
                    <div v-if="saveconfig && !$v.configData.testEmailToAddress.required" class="invalid-feedback">
                      {{ $t('pages.config.emailrequired') }}
                    </div>
                    <div v-if="saveconfig && !$v.configData.testEmailToAddress.email" class="invalid-feedback">
                      {{ $t('pages.config.validemail') }}
                    </div>
                  </b-form-group>
                  <b-form-group>
                    <b-button variant="primary" type="submit" class="ml-2">{{ $t('pages.config.save') }}</b-button>
                  </b-form-group>
                </b-form>
                <div v-if="configData.environment === 'demo'">
                  <p class="mt-1">
                    <em><strong>Let op: U mailt nu met uw demo account. Klanten krijgen geen e-mail!</strong></em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="emailData.length">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Toon&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;Email
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                table-class="table table-centered w-100"
                thead-tr-class="bg-light"
                :items="emailData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                    <input type="checkbox" class="custom-control-input" :id="`contacusercheck${data.item.id}`" />
                    <label class="custom-control-label" :for="`contacusercheck${data.item.id}`"></label>
                  </div>
                </template> -->

                <template v-slot:cell(holding)="data">
                  {{ data.item.holding }}
                </template>

                <template v-slot:cell(service)="data">
                  {{ data.item.service }}
                </template>

                <template v-slot:cell(emailFromAddress)="data">
                  {{ data.item.emailFromAddress }}
                </template>

                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="editTemplate(data.item.id)" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" @click="deleteTemplate(data.item.id)" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-body">Nog geen email geconfigureerd</div>
        </div>
      </div>
      <!-- MODAL -->
      <b-modal id="modal-1" v-model="showModal" :title="$t('pages.profiles.addprofile')" header-close-variant="light" title-class="font-18" hide-footer>
        <form @submit.prevent="addTemplate">
          <div class="form-group">
            <label for="holding">{{ $t('pages.config.holding') }}</label>
            <multiselect v-model="template.holding" :options="holdings"></multiselect>
            <div v-if="submitted && !$v.template.holding.required" class="invalid-feedback">
              {{ $t('pages.config.emailrequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="holding">{{ $t('pages.config.service') }}</label>
            <multiselect v-model="template.service" :options="services"></multiselect>
            <div v-if="submitted && !$v.template.service.required" class="invalid-feedback">
              {{ $t('pages.config.emailrequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="template">{{ $t('pages.config.template') }}</label>
            <input
              id="template"
              v-model="template.template"
              type="text"
              class="form-control"
              placeholder="Enter template name"
              :class="{ 'is-invalid': submitted && $v.template.template.$error }"
            />
            <div v-if="submitted && !$v.template.template.required" class="invalid-feedback">
              {{ $t('pages.profiles.namerequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="template">{{ $t('pages.config.url') }}</label>
            <input
              id="url"
              v-model="template.url"
              type="text"
              class="form-control"
              placeholder="Enter template url"
              :class="{ 'is-invalid': submitted && $v.template.url.$error }"
            />
            <div v-if="submitted && !$v.template.url.required" class="invalid-feedback">
              {{ $t('pages.profiles.namerequired') }}
            </div>
          </div>

          <div class="form-group">
            <label for="name"
              >{{ $t('pages.config.isdefault') }}<br />
              <small>Gebruik deze wanneer holding mist, of als standaard template</small></label
            >
            <div>
              <switches v-model="template.isDefault" v-if="!template.isDefault"></switches>
              <switches v-model="template.isDefault" v-if="template.isDefault" color="success" class="ml-1"></switches>
            </div>
          </div>

          <div class="form-group">
            <label for="emailfromaddress">{{ $t('pages.config.emailfromaddress') }}</label>
            <input
              id="emailfromaddress"
              v-model="template.emailFromAddress"
              type="text"
              class="form-control"
              placeholder="Enter email From address"
              :class="{ 'is-invalid': submitted && $v.template.emailFromAddress.$error }"
            />
            <div v-if="submitted && !$v.template.emailFromAddress.required" class="invalid-feedback">
              {{ $t('pages.config.emailrequired') }}
            </div>
            <div v-if="submitted && !$v.template.emailFromAddress.email" class="invalid-feedback">
              {{ $t('pages.config.validemail') }}
            </div>
          </div>

          <div class="text-right">
            <button v-if="!template.id && !loading" type="submit" class="btn btn-success">{{ $t('pages.config.add') }}</button>
            <button v-if="template.id && !loading" @click="saveTemplate()" class="btn btn-success">{{ $t('pages.config.update') }}</button>
            <b-button variant="primary" disabled class="ml-1" v-if="loading">
              <b-spinner small></b-spinner>&nbsp;&nbsp; {{ $t('pages.config.save') }}
            </b-button>
            <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('pages.config.cancel') }}</b-button>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>
